import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";

import ContactThree from "@/components/contact-three";
import ContactMap from "@/components/contact-map";
import Footer from "@/components/footer";
import HeaderWrapper from "@/components/header-wrapper";
import contactBanner from "@/images/DDImages/2.png";

const Contact = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="Contact Page">
        <HeaderOne />
        {/* <PageHeader title="Contact Us" name="Contact" /> */}
        <HeaderWrapper
          title="Contact Us"
          name="contactUs"
          banner={contactBanner}
        />
        <ContactThree />
        <ContactMap />
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default Contact;
