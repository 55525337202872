import React from "react";

const ContactMap = () => {
  return (
    <div className="map_section">
      <div id="map">
        <iframe
          title="template google map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d219.74962939950447!2d84.00466057277254!3d28.20749274230857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1694342445633!5m2!1sen!2snp"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMap;
